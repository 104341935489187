'use client'

import { useProductVersions } from "@/contexts/VersionsProvider";
import { searchParamsVersionedContent } from "@/lib/searchParams";
import { Listbox } from "@headlessui/react";
import { ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { useQueryStates } from "nuqs";
import { Fragment, useEffect } from "react";
import { gt, coerce } from "semver";

// React component to select versions from a dropdown box
export function VersionSelector({available}: {
    available: string[]
}) {

  const { setPreferred, versions, current, preferred } = useProductVersions()
  const [params, setParams] = useQueryStates(searchParamsVersionedContent)

  useEffect(() => {
    if (params.v === null && (preferred ?? current)) {
      setParams({v: preferred ?? current}, {shallow: false})
    }
  }, [params.v, preferred, current, setParams])

  function setVersion(v: string) {
    if (versions?.includes(v)) {
      setPreferred(v)
      setParams({v}, {
        shallow: false
      })
    }
  }

  if (!versions?.length) {
    return <></>
  }

  return (
    <>
    <Listbox 
      value={preferred ?? current} 
      onChange={setVersion}>
      <Listbox.Button className="text-left print:hidden w-full p-3 mb-2 rounded border-gray-500 border bg-white flex">
        <span className="flex-grow">Version: {params.v ?? current}</span> <ChevronUpDownIcon className='ml-2 w-6 overflow-visible text-gray-600 self-end' />
      </Listbox.Button>
      <div className="relative z-10">
      <Listbox.Options className="absolute text-left print:hidden w-full p-3 mb-2 rounded border-gray-500 border bg-white max-h-96 overflow-y-auto">
        {versions?.map((version) => (
          <Listbox.Option
          disabled={!available.includes(version)}
          as={Fragment}
          key={version}
          value={version}
        >
          {({active}) => (
            <li className={`${active ? 'bg-gray-300' : ''} ${!available.includes(version) ? 'text-gray-600' : ''} p-3 mb-2 rounded cursor-pointer`}>{version}</li>
          )}
          </Listbox.Option>
        ))}
      </Listbox.Options>
      </div>
    </Listbox>
    </>
  );
}