import { DrupalMetaTag, DrupalNode, DrupalTaxonomyTermProduct } from "@/types"
import { formatISO } from "date-fns"
import { Metadata } from "next"
import { DrupalMenuLinkContent } from "next-drupal"

/**
 * Logic for default version selection.
 */
export function useVersion(preference: string | undefined, versions: string[]) {
  return preference ?? (versions.length ? versions[0] : null)
}

export function getLandingPage(product: DrupalTaxonomyTermProduct) {
  return product.field_landing_page.uri?.replace('internal:', '')
}

export function getMetaTags(node: DrupalNode): Metadata {
  const metatags = node.metatag?.filter(t => t.tag == "meta") as DrupalMetaTag[]
  const product = node.field_product.name
  const meta:Metadata = {
    title: [metatags.filter(t => t.attributes.name == 'title').pop()?.attributes.content, product].join(' | '),
    description: metatags.filter(t => t.attributes.name == 'description').pop()?.attributes.content,
    other: {
      type: getMetaType(node.type)
    }
  }

  if (node.type == 'node--release' && meta.other != undefined) {
    meta.other['dcterms.issued'] = formatISO(new Date(node.field_release_date))
  }
  return meta
}

function getMetaType(type: DrupalNode["type"]) {
  switch (type) {
    case 'node--article':
      return 'Article'
    case 'node--page':
      return 'Documentation'
    case 'node--release':
      return 'Release'
    default:
      return 'Documentation'
  }
}

export function getActiveTrail(menu: DrupalMenuLinkContent[], pathname: string, trail: DrupalMenuLinkContent[] = []):DrupalMenuLinkContent[] | false {
  for (let i=0; i < menu.length; i++) {
      const item = menu[i]
      if (item.url.replace(/\?.*/, '') == pathname) {
          return [...trail, item]
      }
      if (item.items) {
          const activeTrail = getActiveTrail(item.items, pathname, [...trail, item])
          if (activeTrail) {
              return activeTrail
          }
      }
  }
  return false
}