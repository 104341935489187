"use client";
import { usePathname } from "next/navigation";
import Link from "next/link";
import { DrupalMenuLinkContent } from "next-drupal";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/20/solid";
import { HTMLAttributes, useId, useState, useRef, useEffect } from "react";
import classNames from "classnames";
import { getActiveTrail } from "@/lib/drupal/util";

interface MenuProps extends HTMLAttributes<any> {
  menu?: DrupalMenuLinkContent[];
  query?: string;
  queryMatchBasePath?: string;
}

export function Menu({ menu, queryMatchBasePath, query, ...props }: MenuProps) {
  const pathname = usePathname();
  const showGotoButtonRef = useRef(false); // Track visibility without re-rendering
  const gotoButtonRef = useRef(null); // Reference to the "Go to Active Menu Item" button


  useEffect(() => {
    // Function to check if any active menu item is collapsed (hidden)
    function checkIfActiveItemIsHidden() {
      if (!trail) return false;

      return trail.some((link) => {
        const menuItem = document.getElementById(link.id);
        return menuItem && menuItem.getAttribute("aria-expanded") === "false";
      });
    }

    const observer = new MutationObserver(() => {
      const isHidden = checkIfActiveItemIsHidden();
      if (showGotoButtonRef.current !== isHidden) {
        showGotoButtonRef.current = isHidden;

        // Update the class directly based on the collapsed state
        if (gotoButtonRef.current) {
          const buttonElement = gotoButtonRef.current as HTMLButtonElement;
          buttonElement.classList.toggle('sr-only', !isHidden);
        }
      }
    });

    // Observe changes in the entire menu
    const menuElement = document.querySelector("nav[data-cy='menu']");
    if (menuElement) {
      observer.observe(menuElement, { attributes: true, subtree: true });
    }

    return () => observer.disconnect();
  });

  if (!menu?.length) {
    return null;
  }
  const trail = getActiveTrail(menu, pathname);


  function inActiveTrail(url: string): boolean {
    if (!trail) {
      return false;
    }
    return trail.filter((link) => link.url == url).length > 0;
  }

  function transformLink(link: DrupalMenuLinkContent) {
    if (query === undefined) {
      return link;
    }
    if (queryMatchBasePath && !link.url.startsWith(queryMatchBasePath)) {
      return link;
    }

    if (!link.url.includes("?")) {
      link.url += "?" + query;
    }

    if (link.items) {
      link.items = link.items.map((item) => transformLink(item));
    }
    return link;
  }

  function MenuItem(item: DrupalMenuLinkContent & { className?: string }) {
    const isActive = pathname === item.url.replace(/\?.*/, "");
    const isExpanded = inActiveTrail(item.url);
    const hasChildren = !!item.items?.length;
    const groupId = useId();
    const [open, setOpen] = useState(isExpanded);

    // Toggle the open state for the menu item
    function toggleMenu() {
      setOpen(!open);
    }

    return (
      <div className="flex flex-col">
        {hasChildren ? (
          <button
            aria-expanded={open}
            aria-haspopup={hasChildren}
            aria-controls={hasChildren ? groupId : undefined}
            className={classNames(
              "flex items-center justify-between rounded-lg p-2 text-left transition-colors duration-300 hover:bg-gray-500 mb-1",
              item.className
            )}
            onClick={hasChildren ? toggleMenu : undefined}
            role="menuitem"
            title={`${open ? "Collapse" : "Expand"} ${item.title}`}
            id={item.id}
          >
            {item.title}
            {open ? (
              <ChevronUpIcon className="ml-2 w-4 h-4" />
            ) : (
              <ChevronDownIcon className="ml-2 mt-1 w-4 h-4" />
            )}
          </button>
        ) : (
          <Link
            href={item.url}
            className={classNames(
              "flex items-center justify-between rounded-lg p-2 transition-colors duration-300 hover:bg-gray-500 mb-1",
              isActive ? "bg-gray-500 font-bold" : "",
              item.className
            )}
            aria-current={isActive ? "page" : undefined}
            role="menuitem"
            id={isActive ? "active-menu-item" : item.id}
          >
            {item.title}
          </Link>
        )}
        {open && hasChildren && (
          <div
            id={groupId}
            role="menu"
            className="flex flex-col ps-5 gap-y-2"
            aria-live="polite"
          >
            {item.url != "" && (
              <MenuItem {...{ ...item, title: "Overview", items: undefined }} />
            )}

            {item.items?.map((link) => (
              <MenuItem key={link.id} {...link} />
            ))}
          </div>
        )}
      </div>
    );
  }

  // Handle active menu item click event
  function handleGotoActiveMenuItemClick() {
    if (trail) {
      trail.forEach((link, index) => {
        const menuItem = document.getElementById(link.id);
        if (menuItem && menuItem.getAttribute("aria-expanded") === "false") {
          menuItem.click();
        }
      });

      // Use a timeout to delay the scroll action, allowing time for menu to expand
      setTimeout(() => {
        const activeItem = document.getElementById("active-menu-item");
        if (activeItem) {
          // Scroll to the active item after a brief delay and focus it
          activeItem.scrollIntoView({ behavior: "smooth", block: "center" });
          activeItem.focus();
        }
      }, 0);
    }
  }

  if (query !== undefined) {
    menu = menu.map((link) => transformLink(link));
  }

  return (
    <nav data-cy="menu" role="menu" {...props}>
      <button
        ref={gotoButtonRef}
        className="mb-1 underline justify-between p-2 flex rounded-md sr-only"
        onClick={handleGotoActiveMenuItemClick}
      >
        Go to Active Menu Item
      </button>
      {menu.map((link) => (
        <MenuItem key={link.id} className="font-bold" {...link} />
      ))}
    </nav>
  );
}
